<template lang="html">
    <div class="card card-primary card-outline">
        <div class="card-header">
            <h3 class="card-title">验证手机</h3>
            <div class="card-tools mr-0">
            </div>
        </div>

        <!-- /.box-header -->
        <div class="card-body">
            <div class="row">
                <div class="col-sm-6 col-md-4 col-lg-4" v-if="user">
                    <!--<div class="form-group">
                        <label for="email">Email</label>
                        <input type="email" class="form-control" :class="{ 'is-invalid': $v.user.email.$error }" id="email" v-model.trim="$v.user.email.$model" placeholder="Email" :readonly="user.emailConfirmed">
                        <div class="invalid-feedback">
                            <span v-if="!$v.user.email.required">请输入 Email</span>
                            <span v-if="!$v.user.email.email">无效的 Email 格式</span>
                        </div>
                    </div>-->
                    <div class="form-group">
                        <label for="phoneNumber">手机号码</label>
                        <div class="input-group">
                            <input type="tel" class="form-control" :class="{ 'is-invalid': $v.user.phoneNumber.$error }" id="phoneNumber" v-model.trim="$v.user.phoneNumber.$model" placeholder="手机号码" :readonly="user.phoneNumberConfirmed" maxlength="11">
                            <div class="input-group-prepend">
                                <button class="btn btn-primary" :disabled="codesending||waitTime>0||$v.user.phoneNumber.$error" @click="phoneSendCode">
                                    <span v-if="codesending">重新发送</span>
                                    <span v-else-if="waitTime>0">等待{{ waitTime }}秒</span>
                                    <span v-else>发送验证码</span>
                                </button>
                            </div>
                            <div class="invalid-feedback">
                                <span v-if="!$v.user.phoneNumber.required">请输入手机号码</span>
                                <span v-if="!$v.user.phoneNumber.isMobile">无效的手机号码格式</span>
                                <span v-if="!$v.user.phoneNumber.isUnique">此号码已经被其它账户绑定</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="phoneNumber">验证码</label>
                        <div class="input-group">
                            <input type="text" class="form-control" v-model.trim="code" placeholder="请输入验证码" maxlength="4" autocomplete="off">
                            <div class="input-group-prepend">
                                <button class="btn btn-primary" :disabled="!canSubmit" @click="phoneSubmit">
                                    确认
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- /.box-body -->

        <div class="card-footer">
            <router-link class="btn btn-default" :to="{ name: 'Profile'}">取消</router-link>
        </div>    <!-- Loading (remove the following to stop the loading)-->
        <div class="overlay" v-if="updateing">
            <i class="fas fa-spinner fa-pulse"></i>
        </div>
        <!-- end loading -->

    </div>
</template>

<script>
    import { required, minLength, maxLength, email, helpers } from 'vuelidate/lib/validators'
    const isMobile = helpers.regex('isMobile', /^1(3|4|5|6|7|8|9)\d{9}$/)
    const isTel = helpers.regex('isTel', /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{8}$/)
    const isUrl = helpers.regex('isUrl', /^((http:\/\/)|(https:\/\/))?([a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.?)+(([a-zA-Z]{2,6})|(:[0-9]{1,5}))$/)

    export default {
        data() {
            return {
                user: null,
                updateing: false,

                //phongcheck
                phone: null,
                code: '',
                codesending: false,
                //canSubmit: false,
                timer: null,
                waitTime: 0
            }
        },
        computed: {
            canSubmit() {
                if (!this.code) {
                    return false
                }
                return this.code.length == 4
            }
        },
        validations: {
            user: {
                userName: {
                    required,
                    minLength: minLength(3),
                    maxLength: maxLength(30),
                    isUnique(value) {
                        // standalone validator ideally should not assume a fields is required
                        if (value === '') return true
                        // simulate async call, fail for all logins with even length
                        return new Promise((resolve, reject) => {
                            this.$axios.get(`/Api/My/Users/CheckUserName/${value}`)
                                .then(function (response) {
                                    //console.log(response)
                                    resolve(response.data)
                                }).catch(function (error) {
                                    console.error(error)
                                })
                        })
                    }
                },
                nickName: {
                    required,
                    minLength: minLength(3),
                    maxLength: maxLength(30),
                },
                fullName: {
                    required,
                    minLength: minLength(2),
                    maxLength: maxLength(10),
                },
                email: {
                    required,
                    email
                },
                phoneNumber: {
                    required,
                    isMobile,
                    isUnique(value) {
                        // standalone validator ideally should not assume a fields is required
                        if (value === '') return true
                        // simulate async call, fail for all logins with even length
                        return new Promise((resolve, reject) => {
                            this.$axios.get(`/Api/My/Users/CheckPhoneNumber/${encodeURI(value)}`)
                                .then(function (response) {
                                    //console.log(response)
                                    resolve(response.data)
                                }).catch(function (error) {
                                    console.error(error)
                                })
                        })
                    }
                },
            }
        },
        created() {
            this.loadData()
        },
        methods: {
            loadData() {
                let _this = this
                var url = "/Api/My/Users"
                var params = {
                }

                this.$axios.get(url, { params: params })
                    .then(function (response) {
                        console.log(response)
                        _this.user = response.data
                    }).catch(function (error) {
                        console.log(error)
                    })

            },
            phoneSendCode() {
                this.$v.user.phoneNumber.$touch()
                if (this.$v.user.phoneNumber.$invalid) {
                    return
                }
                const _this = this
                this.$axios.post('/Api/Verify/SendVerifySMS', { phone: this.user.phoneNumber }).then((res) => {
                    _this.code = null
                    _this.waitTime = 60
                    _this.timer = setInterval(() => {
                        _this.waitTime--
                        if (_this.waitTime <= 0) {
                            clearInterval(_this.timer)
                        }
                    }, 1000)
                }).catch((err) => {

                })
            },
            phoneSubmit() {
                const _this = this
                this.$axios.post('/Api/My/Users/ConfirmPhoneNumber', { phone: this.user.phoneNumber, verifyCode: this.code }).then((res) => {
                    if (res.data) {
                        this.phoneChecked = true
                        this.$message({
                            message: '验证成功',
                            type: 'info',
                            zIndex: 9999,
                        })
                        this.$router.push({ name: 'Profile' })
                    } else {
                        this.$message({
                            message: '验证码错误',
                            type: 'error',
                            zIndex: 9999,
                            //showClose: true
                        })
                    }
                }).catch((err) => {
                    console.error(err)
                })
            },
        },
        components: {
        },
    }
</script>

<style lang="css">
    .top_container {
        width: 100%;
        position: relative;
        display: inline-block;
        max-width: 300px;
    }

    .dummy {
        margin-top: 100%;
    }

    .cropper {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
</style>
